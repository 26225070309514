import React, {useContext, useEffect} from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { Link, useHistory, useLocation } from 'react-router-dom'
import AuthLayout from 'layouts/AuthLayout'
import UnprotectedPage from 'components/organisms/UnprotectedPage/UnprotectedPage'
import AuthApi from 'api/auth'
import useAbstractMutator from 'providers/AbstractMutator'
import { useTranslation } from 'react-i18next'
import useAbstractProvider from '../providers/AbstractProvider'
import UserApi from '../api/user'
import {getTheRightRole, ROLES} from '../utils'
import {StoreContext} from '../App'

const useStyles = makeStyles({
  signInContainer: {
    height: 'auto',
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: "center",
    background: '#fff',
    padding: '30px 30px 30px 30px',
    borderRadius: '0px',
  },
  signInHeading: {
    fontWeight: 800,
    textAlign: 'center',
  },
  errorMessage: {
    height: '50px',
  },
  signInButton: {
    width: '100%',
    marginTop: 10,
  },
  signUpLink: {
    textDecoration: 'underline',
    marginLeft: 5,
    cursor: 'pointer',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20,
  },
  externalLogin: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
  },
  externalLoginButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  externalLoginText: {
    marginBottom: 10,
  },
  googleLogin: {
    marginRight: 10,
  },
  linkContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  link: {
    textDecoration: 'underline',
  },

})

const LoginPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const code = urlParams.get('code')
  const { t } = useTranslation()
  const {
    data: openIdLoginData,
    error: openIdLoginError,
    mutate: openIdLogin,
  } = useAbstractMutator(AuthApi.openIdLogin)
  const store = useContext(StoreContext)
  const { data: openIdVerifyUserData, mutate: openIdVerifyUser } = useAbstractMutator(
    AuthApi.openIdVerifyUser,
  )

  useEffect(() => {
    if (openIdLoginData) {
      window.location.replace(openIdLoginData)
    }
  }, [openIdLoginData, openIdLoginError])

  useEffect(() => {
    if (code) {
      openIdVerifyUser({ code })
    }
  }, [code])

  const { data: currentlyLoggedInUser, status, refetch: refetchCurrentUser } = useAbstractProvider(
    UserApi.getCurrentlyLoggedInUser, null, false
  )

  useEffect(() => {
    if (currentlyLoggedInUser) {
      if (getTheRightRole(currentlyLoggedInUser?.ssoData) === ROLES.manager) {
        store.setSelectedView('manager')
        history.push('/manager-view')
      } else {
        store.setSelectedView('mentor')
        history.push('/mentors-view')
      }
    }
  }, [currentlyLoggedInUser])

  useEffect(() => {
    if (openIdVerifyUserData) {
      switch (openIdVerifyUserData.role) {
        case 'mentor':
          refetchCurrentUser()
          break
        case 'openid':
          break
        case 'admin':
        case 'trainee':
        default:
          store.setSelectedView(openIdVerifyUserData.role)
          history.push('/')
      }
    }
  }, [openIdVerifyUserData])

  return (
    <UnprotectedPage>
      {AuthLayout.getLayout(
        <div>
          <Paper className={classes.signInContainer}>
            <h1 className={classes.signInHeading}>{t('sign_in.heading')}</h1>

            <Grid spacing={3} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <CustomButton
                  color="primary"
                  className={classes.signInButton}
                  onClick={() => openIdLogin(null)}
                >
                  {t('sign_in.button')}
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
          <div className={classes.linkContainer}>
            <Link  to="/classic-sign-in">
            <span className={classes.link}>{t('sign_in.local-user')}</span>
            </Link>
          </div>
        </div>,
      )}
    </UnprotectedPage>
  )
}

export default LoginPage
