import UserApi from 'api/user'
import MaterialTable from 'components/organisms/MaterialTable/MaterialTable'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
// import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import Loader from 'components/atoms/Loader/Loader'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import SimpleDialog from 'components/organisms/SimpleDialog/SimpleDialog'
import ChannelSelector from 'components/organisms/ChannelSelector/ChannelSelector'
import { getRangeOfNumbers } from 'utils'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import { debounce } from 'lodash'
import MainLayout from '../../layouts/MainLayout'
import ProtectedPage from '../../components/organisms/ProtectedPage/ProtectedPage'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    marginBottom: 50,
  },
  heading: {
    marginBottom: 45,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
})

const TraineeDashboard = () => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [data, setData] = useState([])
  const history = useHistory()
  const [page, setPage] = useState(0)
  const { t } = useTranslation()
  const [startingMonth, setStartingMonth] = useState(moment().month())
  const [endingMonth, setEndingMonth] = useState(moment().month())
  const [year, setYear] = useState(String(moment().year()))
  const [isChannelDialogOpen, setIsChannelDialogOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>()
  const [sortBy, setSortBy] = useState('1')

  const months = moment.months().map((month, index) => ({ name: month, value: String(index) }))
  const years = getRangeOfNumbers(2020, moment().year()).map((yearItem) => ({
    name: String(yearItem),
    value: String(yearItem),
  }))

  const users = useAbstractProvider(
    UserApi.getAllTeamUsersData,
    {
      search,
      offset,
      limit,
      year,
      startingMonth,
      endingMonth,
      sortBy,
    },
    false,
  )

  const head = [
    { name: t('first_name'), dbName: 'firstName' },
    { name: t('last_name'), dbName: 'lastName' },
    { name: t('admin_statistics.table_logins'), dbName: 'logins' },
    { name: t('admin_statistics.table_time_spent'), dbName: 'timeSpent', type: 'time' },
    { name: t('admin_statistics.last_login'), dbName: 'lastLogin' },
    { name: t('admin_statistics.feedback_status'), dbName: 'feedbackStatus' },
  ]

  function fetchData(
    newSearch,
    newOffset,
    newLimit,
    newYear,
    newStartingMonth,
    newEndingMonth,
    newSortBy,
  ) {
    users.refetch({
      search: newSearch,
      offset: newOffset,
      limit: newLimit,
      year: newYear,
      startingMonth: newStartingMonth,
      endingMonth: newEndingMonth,
      sortBy: newSortBy,
    })
  }

  const debounceLoadData = useCallback(debounce(fetchData, 800), [])

  useEffect(() => {
    debounceLoadData(search, offset, limit, year, startingMonth, endingMonth, sortBy)
  }, [offset, limit, search, year, startingMonth, endingMonth, sortBy])

  useEffect(() => {
    if (users?.data) {
      let newData = [...users.data.data]
      newData = newData.map((user) => {
        const newUser = {
          ...user,
          lastLogin: user.lastLogin
            ? moment(user.lastLogin).format('YYYY/MM/DD - hh:mm')
            : t('mentor_dashboard.never_logged_in'),
        }
        return newUser
      })
      setData(newData)
    }
  }, [users.data])

  function handleChangePage(pageToChange) {
    setOffset(pageToChange * limit)
  }

  const selectItem = (selectedItem) => {
    setSelectedUser(selectedItem)
    setIsChannelDialogOpen(true)
  }

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          {/* <Text variant="h2" className={classes.heading}>
        Trainee {t('mentor_dashboard.heading')}
      </Text> */}
          <Grid container alignItems="center" style={{ marginBottom: '0.5rem' }} spacing={1}>
            <Grid item xs={4} sm={2}>
              <MaterialSelect
                value={year}
                label={t('mentor_dashboard.year')}
                onChange={(selectedYear) => setYear(selectedYear)}
                options={years}
              />
            </Grid>
            <Grid item xs={4} sm={2} alignItems="center">
              <MaterialSelect
                value={months[startingMonth].value}
                label={t('mentor_dashboard.starting_month')}
                onChange={(monthIndex) => setStartingMonth(Number(monthIndex))}
                options={months}
              />
            </Grid>
            <Grid item xs={4} sm={2} alignItems="center">
              <MaterialSelect
                value={months[endingMonth].value}
                label={t('mentor_dashboard.ending_month')}
                onChange={(monthIndex) => setEndingMonth(Number(monthIndex))}
                options={months}
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ marginBottom: -30 }}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('mentor_dashboard.search_by_name')}
                name="search"
                value={search}
                onChange={(searchWord) => {
                  setPage(0)
                  setOffset(0)
                  setSearch(searchWord)
                }}
              />
            </Grid>
            <Grid item xs={5} sm={2}>
              <MaterialSelect
                value={sortBy}
                label={t('mentor_dashboard.sort_by')}
                onChange={(value) => setSortBy(value)}
                options={[
                  { name: t('mentor_dashboard.name_asc'), value: '1' },
                  { name: t('mentor_dashboard.name_desc'), value: '2' },
                  { name: t('mentor_dashboard.logins_asc'), value: '3' },
                  { name: t('mentor_dashboard.logins_desc'), value: '4' },
                  { name: t('mentor_dashboard.time_spent_asc'), value: '5' },
                  { name: t('mentor_dashboard.time_spent_desc'), value: '6' },
                  { name: t('mentor_dashboard.last_login_asc'), value: '7' },
                  { name: t('mentor_dashboard.last_login_desc'), value: '8' },
                ]}
              />
            </Grid>
          </Grid>

          {users.loading ? (
            <Loader />
          ) : (
            <div className={classes.table}>
              <MaterialTable
                head={head}
                data={data}
                onRowClick={selectItem}
                count={users?.data?.count}
                onChangePage={handleChangePage}
                page={page}
                setPage={setPage}
                rowsPerPage={limit}
                onChangeRowsPerPage={(value) => {
                  setLimit(value)
                  setOffset(0)
                }}
              />
              <SimpleDialog
                open={isChannelDialogOpen}
                handleClose={() => setIsChannelDialogOpen(false)}
                title={t('mentor_dashboard.choose_channel', {
                  name: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
                })}
              >
                <ChannelSelector
                  onChange={(selectedChannel) => {
                    history.push(
                      `/mentors-view/user-detail/userId=${selectedUser?.userId}/channelId=${selectedChannel.id}`,
                    )
                  }}
                  channelIds={data.find((item) => item.userId === selectedUser?.userId)?.channels}
                />
              </SimpleDialog>
            </div>
          )}
        </div>
      )}
    </ProtectedPage>
  )
}

export default TraineeDashboard
