import React from 'react'
import { Route } from 'react-router-dom'
import ManagePage from 'pages/ManagePage'
import Channels from 'pages/Channels'
import ChannelImport from 'pages/ChannelImport'
import StaticContentsPage from 'pages/StaticContentsPage'
import StaticContentEditPage from 'pages/StaticContentEditPage'
import Products from 'pages/Products'
import Phases from 'pages/Phases'
import ConversationGroups from 'pages/ConversationGroups'
import Conversations from 'pages/Conversations'
import StatisticsDashboard from './pages/MentorsPage/StatisticsDashboard'

const ContentManagerRoutes = () => (
  <>
    <Route exact path="/admin-view/learning-material-managment">
      <Channels />
    </Route>
    <Route exact path="/admin-view/learning-material-managment-import">
      <ChannelImport />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId">
      <Products />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId">
      <Phases />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId/phaseId=:phaseId">
      <ConversationGroups />
    </Route>
    <Route
      exact
      path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId/phaseId=:phaseId/conversationGroupId=:conversationGroupId"
    >
      <Conversations />
    </Route>
    <Route exact path="/admin-view/static-contents">
      <StaticContentsPage />
    </Route>
    <Route exact path="/admin-view/static-contents/:staticContentId">
      <StaticContentEditPage />
    </Route>
    <Route exact path="/admin-view/statistics">
      <StatisticsDashboard />
    </Route>
    <Route exact path="/">
      <ManagePage />
    </Route>
  </>
)

export default ContentManagerRoutes
