import { BACKEND_URL } from './configuration'

const ADDITIONAL_URLS =
  'https://ipv4.icanhazip.com/ https://api.ipify.org/ https://cdnjs.cloudflare.com/ https://www.google.com https://www.gstatic.com/recaptcha/ https://cdn.tiny.cloud https://sp.tinymce.com/'

const initContentSecurityPolicy = (cloudfrontUrl = '') => {
  const meta = document.createElement('meta')
  meta.httpEquiv = 'Content-Security-Policy'
  meta.content = `
      default-src ${BACKEND_URL} ws: wss:  ${ADDITIONAL_URLS} ${cloudfrontUrl} 'self' 'unsafe-inline' blob:;
      connect-src ${BACKEND_URL} ws: wss: ${ADDITIONAL_URLS} ${cloudfrontUrl} 'self' 'unsafe-inline' blob:;
      img-src ${cloudfrontUrl} ${BACKEND_URL} https://sp.tinymce.com/ 'self' data: blob:; 
      style-src https://cdn.tiny.cloud 'unsafe-inline';
      worker-src blob: 'self';
      child-src blob: https://www.google.com/;
    `
  document.getElementsByTagName('head')[0].appendChild(meta)
}

export default initContentSecurityPolicy
