import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import React, { createContext, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

import {
  openSansBold,
  openSansItalic,
  openSansRegular,
  publicoHeadlineBold,
} from 'fonts/fonts'
import { showToast } from 'utils'
import { useTranslation } from 'react-i18next'
import AudioContext from 'helpers/audioContext'
import MainRoutes from 'MainRoutes'
import useAbstractProvider from 'providers/AbstractProvider'
import ConfigsApi from 'api/configs'
import ActivationPage from 'pages/ActivationPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import ChangePasswordPage from 'pages/ChangePasswordPage'
import TermsPage from 'pages/TermsPage'
import DataProtectionPage from 'pages/DataProtectionPage'
import { ToastContainer } from 'react-toastify'
import OfflinePage from 'pages/OfflinePage'
import ContactPage from 'pages/ContactPage'
import ClassicLoginPage from 'pages/ClassicLoginPage'
import LoginPage from 'pages/LoginPage'
import Store from './stores'
import 'App.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#00008F',
      dark: '#000072',
      light: '#4040AB',
    },
    secondary: {
      main: '#CCCCE9',
    },
    text: {
      primary: '#333333',
    },
    error: {
      main: '#A11028',
    },
    warning: {
      main: '#F07662',
    },
    info: {
      main: '#00ADC6',
    },
    success: {
      main: '#169E3E',
    }
  },
  typography: {
    fontFamily: ['"Open Sans"', 'ui-sans-serif', 'system-ui', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif'].join(
      ',',
    ),
    h1: {
      fontSize: '3rem',
      fontFamily: '"Publico Headline", "Times New Roman", Times, serif',
      fontWeight: 'bold',
      color: '#333333',
      lineHeight: 1.167,
    },
    h2: {
      fontSize: '2.25rem', // 36px
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 700,
      color: '#00008F',
      lineHeight: 1.167,
    },
    h3: {
      fontSize: '1.75rem', // 28px
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 700,
      color: '#00008F',
      lineHeight: 1.167,
    },
    h4: {
      fontSize: '1.5rem', // 24px
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 700,
      color: '#4040ab',
      lineHeight: 1.167,
    },
    h5: {
      fontSize: '1.3rem', // 20px
      fontFamily: 'Open Sans',
      color: '#00008F',
    },
    body1: {
      fontSize: '1rem', // 16px
      fontFamily: '"Open Sans", sans-serif',
      fontWeight: 400,
      color: '#333333',
      lineHeight: 1.375,
    },
    body2: {
      fontSize: '16px',
    },
    caption: {
      fontSize: '16px',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          publicoHeadlineBold,
          openSansRegular,
          openSansBold,
          openSansItalic,
        ],
      },
    },
  },
})

export const StoreContext = createContext({} as any)

const store = new Store()

function App() {
  const { data: configs } = useAbstractProvider(ConfigsApi.getConfigs)
  const { data: logo } = useAbstractProvider(ConfigsApi.getLogo)
  const { i18n, t } = useTranslation()

  store.setLanguage(i18n.resolvedLanguage === 'en' ? 'de' : i18n.resolvedLanguage)

  useEffect(() => {
    if (!AudioContext) {
      showToast(t('audiocontext_incompatibility_error'))
    }
  }, [])

  useEffect(() => {
    if (configs) {
      store.setConfigs(configs)
    }
  }, [configs])

  useEffect(() => {
    if (logo) {
      store.setLogo(`data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(logo)))}`)
    }
  }, [logo])

  return (
    <StoreContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div lang={i18n.resolvedLanguage} className="App">
          <Switch>
            <Route exact path="/sign-in">
              <LoginPage />
            </Route>
            <Route exact path="/classic-sign-in">
              <ClassicLoginPage />
            </Route>
            <Route exact path="/activate/token=:token">
              <ActivationPage />
            </Route>
            <Route exact path="/reset-password">
              <ResetPasswordPage />
            </Route>
            <Route exact path="/reset-password/token=:token">
              <ChangePasswordPage />
            </Route>
            <Route exact path="/gdpr">
              <DataProtectionPage />
            </Route>
            <Route exact path="/offline">
              <OfflinePage />
            </Route>
            <Route exact path="/terms">
              <TermsPage />
            </Route>
            <Route exact path="/contact">
              <ContactPage />
            </Route>
            <MainRoutes />
          </Switch>
          <ToastContainer position="bottom-left" closeOnClick pauseOnHover autoClose={4000} />
        </div>
      </ThemeProvider>
    </StoreContext.Provider>
  )
}

export default App
