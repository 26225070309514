import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {
  IconButton,
  LinearProgress,
  TableFooter,
  TablePagination,
  useTheme,
  SvgIcon,
  Link,
  Grid,
} from '@material-ui/core'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import LastPageIcon from '@material-ui/icons/LastPage'
import _ from 'lodash'
import DescriptionIcon from '@material-ui/icons/Description'
import { v4 as uuidv4 } from 'uuid'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import moment from 'moment'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import green from '@material-ui/core/colors/green'
import { useTranslation } from 'react-i18next'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#143A7A',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  paper: {
    height: '100%',
    borderRadius: '0px'
  },
  row: {
    cursor: 'pointer',
  },
  progressBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 130,
  },
  progressBarText: {
    textAlign: 'center',
  },
  footer: {
    overflow: 'hidden',
    height: '75px',
  },
  progressBar: {
    width: 130,
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const useStyles2 = makeStyles({
  icons: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: 15,
  },
  selectInput: {
    padding: '10px 10px',
  },
})

function TablePaginationActions(props) {
  const classes = useStyles2()
  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  function addAfter(pageNumber, median, result) {
    const after =
      pageNumber <= page + median && pageNumber < Math.ceil(count / rowsPerPage)
        ? { number: pageNumber + 1, type: 'after' }
        : null
    if (after) {
      result.push(after)
      return addAfter(pageNumber + 1, median, result)
    }
    return result
  }

  function addBefore(pageNumber, median, result) {
    const before =
      pageNumber > page - median + 1 && pageNumber > 1
        ? { number: pageNumber - 1, type: 'before' }
        : null
    if (before) {
      result.push(before)
      return addBefore(pageNumber - 1, median, result)
    }

    return result
  }

  function addPaginationNumbers(startPageNumber, numberOfNumbers) {
    const before = addBefore(startPageNumber, numberOfNumbers, [])
    const current = { number: page + 1, type: 'current' }
    const after = addAfter(startPageNumber, numberOfNumbers, [])

    return [...before.reverse(), current, ...after]
  }

  const renderPageNumberPagination = () => {
    const paginationNumbers = addPaginationNumbers(page + 1, 2)

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {paginationNumbers.map((pageNum) => {
          if (pageNum)
            return (
              <div
                key={pageNum.number}
                style={{ margin: '0px 8px', cursor: 'pointer' }}
                onClick={() => {
                  onChangePage(null, pageNum.number - 1)
                }}
              >
                {pageNum.type === 'current' ? <b>{pageNum.number}</b> : pageNum.number}
              </div>
            )
          return null
        })}
      </div>
    )
  }

  return (
    <div className={classes.icons}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>

      {renderPageNumberPagination()}

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

interface MaterialTableInterface {
  head: any
  data: any
  onRowClick?: Function
  count: number
  onChangePage: Function
  page: number
  setPage: Function
  rowsPerPage: number
  onChangeRowsPerPage: Function
}

const MaterialTable: React.FC<MaterialTableInterface> = ({
  head,
  data,
  onRowClick,
  count,
  onChangePage,
  page,
  setPage,
  rowsPerPage,
  onChangeRowsPerPage,
}) => {
  const classes = useStyles()
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    if (onChangePage) {
      onChangePage(newPage)
    }
  }

  const handleChangeRowsPerPage = (event) => {
    if (onChangeRowsPerPage) {
      onChangeRowsPerPage(parseInt(event.target.value, 10))
    }
    setPage(0)
  }

  const { t } = useTranslation()

  const renderBody = (row, item) => {
    let res
    if (item.type === 'progressBar') {
      res = (
        <div className={classes.progressBarContainer}>
          <div className={classes.progressBarText}>{_.get(row, item.dbName).toFixed(2)} %</div>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={_.get(row, item.dbName) || 0}
          />
        </div>
      )
    } else if (item.type === 'argumentations') {
      const argu = _.get(row, item.dbName)

      const groupName = _.get(row, 'groupName')

      res =
        argu.length > 0 &&
        argu.map((arg) => (
          <DescriptionIcon
            key={uuidv4()}
            onClick={item.onClick ? () => item.onClick(arg, groupName) : null}
          />
        ))
    } else if (item.type === 'recordings') {
      const argu = _.get(row, item.dbName)
      const groupName = _.get(row, 'groupName')

      res =
        argu.length > 0 &&
        argu.map((arg) => (
          <PlayCircleFilledIcon
            key={uuidv4()}
            onClick={item.onClick ? () => item.onClick(arg, groupName) : null}
          />
        ))
    } else if (item.type === 'feedback') {
      const chanel = _.get(row, 'channelName')
      const product = _.get(row, 'productName')
      const phase = _.get(row, 'phaseName')
      const group = _.get(row, 'groupName')

      const subject = encodeURIComponent(`Feedback zu ${phase}`)
      const body = encodeURIComponent(
        `Kanal: ${chanel}\nProdukt: ${product}\nSchritt: ${phase}\nAbschnitt: ${group}\n\nFeedback:\n`,
      )

      return (
        <Link
          href={`mailto:${item.email}?subject=${subject}&body=${body}`}
          className={classes.centered}
        >
          <SvgIcon
            onClick={item.onClick ? () => item.onClick(chanel, product, phase, group) : null}
          >
            <path
              d="M3 15H1V3a2 2 0 0 1 2-2h16v2H3v12m9 8a1 1 0 0 1-1-1v-3H7a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4.1l-3.7 3.71c-.2.18-.44.29-.7.29H12M9 9v2h10V9H9m0 4v2h8v-2H9z"
              fill="currentColor"
            />
          </SvgIcon>
        </Link>
      )
    } else if (item.type === 'time') {
      const argu = _.get(row, item.dbName)
      const date = new Date(null)
      date.setSeconds(argu / 1000)
      res = date?.toISOString().substr(11, 8)
    } else if (item.type === 'date') {
      const argu = _.get(row, item.dbName)
      res = argu ? moment(new Date(argu)).format('DD.MM.YYYY') : null
      return res
    } else if (item.type === 'datetime') {
      const argu = _.get(row, item.dbName)
      return argu ? moment(new Date(argu)).format(item.format || 'LLL') : null
    } else if (item.dbName === 'productName') {
      res = (
        <Grid container justifyContent="flex-start" alignItems="center">
          {_.get(row, item.dbName)}{' '}
          {row.learningPercentage >= 100 && row.recordingPercentage >= 100 ? (
            <CheckBoxIcon style={{ marginLeft: 2, color: green[500] }} />
          ) : null}
        </Grid>
      )
    } else if (item.dbName === 'active' || item.dbName === 'licensed') {
      res = _.get(row, item.dbName) ? <CheckIcon /> : <ClearIcon />
    } else {
      res = _.get(row, item.dbName)
    }
    return res
  }

  return (
    <TableContainer component={Paper} classes={{ root: classes.paper }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {head.map((item) => (
              <StyledTableCell
                style={
                  item.dbName === 'active' || item.dbName === 'licensed' ? { width: '10px' } : {}
                }
                key={`head-cell-${item.name}`}
              >
                {item.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow
              key={uuidv4()}
              className={classes.row}
              onClick={() => {
                if (onRowClick) {
                  onRowClick(row)
                }
              }}
            >
              {head.map((item) => (
                <StyledTableCell
                  align={item.dbName === 'active' || item.dbName === 'licensed' ? 'center' : 'left'}
                  key={`body-cell-${item.name}`}
                  component="th"
                  scope="row"
                >
                  {renderBody(row, item)}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter classes={{ root: classes.footer }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={10}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              labelRowsPerPage={t('rows_per_page')}
              onChangePage={handleChangePage}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default MaterialTable
