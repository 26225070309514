import React, { useEffect } from 'react'
import { Link, Route } from 'react-router-dom'

import LearningPage from 'pages/learning/LearningPage'
import ProductsPage from 'pages/learning/ProductsPage'
import TrainingUserDetailPage from 'pages/TrainingUserDetailPage'
import WelcomePage from 'pages/WelcomePage'

const TraineeRoutes = () => (
  <>
    <Route
    exact
      path={[
        '/learn/channelId=:channelId',
        '/learn/channelId=:channelId/productId=:productId',
        '/learn/channelId=:channelId/productId=:productId/phaseId=:phaseId',
        '/learn/channelId=:channelId/productId=:productId/phaseId=:phaseId/conversationGroupId=:conversationGroupId',
      ]}
    >
      <LearningPage />
    </Route>
    <Route exact path="/learn/channelId=:channelId/products">
      <ProductsPage />
    </Route>
    <Route exact path="/dashboard/channelId=:channelId/argumentationId=:argumentationId">
      <TrainingUserDetailPage />
    </Route>
    <Route exact path="/dashboard/channelId=:channelId/recordingId=:recordingId">
      <TrainingUserDetailPage />
    </Route>
    <Route exact path="/dashboard/channelId=:channelId">
      <TrainingUserDetailPage />
    </Route>
    <Route exact path="/dashboard">
      <TrainingUserDetailPage />
    </Route>
    <Route exact path="/">
      <WelcomePage />
    </Route>
  </>
)

export default TraineeRoutes
