import MenuItem from '@material-ui/core/MenuItem'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import { Button, IconButton, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { StoreContext } from 'App'
import { LoggedInContext } from 'context'
import useMedia from 'hooks/useMedia'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import AccountIcon from '@material-ui/icons/AccountCircleRounded'
import { useTranslation } from 'react-i18next'
import useAbstractMutator from 'providers/AbstractMutator'
import AuthApi from 'api/auth'
import useAbstractProvider from '../../../providers/AbstractProvider'
import UserApi from '../../../api/user'

const buttonStyle = {
  color: '#191934',
  lineHeight: 1.75,
  fontSize: 'inherit',
  marginLeft: 15,
  marginRight: 15,
  '@media (max-width: 600px)': {
    marginLeft: 0,
    marginRight: 0,
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItemText: {
    '& .MuiListItemText-primary': {
      color: '#00008f',
      fontWeight: 600,
    },
  },
  menuItemIcon: {
    color: '#00008f',
  },
  menuItemTextInfo: {
    marginBottom: 10,
    '& .MuiListItemText-primary': {
      color: 'grey',
      fontWeight: 600,
    },
    '& .MuiListItemText-secondary': {
      color: 'grey',
      paddingTop: 10,
      fontWeight: 300,
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
  },
  profileContainer: {
    padding: '0px 0px',
    minWidth: 'unset',
  },
  profileContainerMobile: {
    marginRight: 5,
  },
  pointer: {
    cursor: 'pointer',
  },
  link: buttonStyle,
  links: {
    display: 'flex',
    alignItems: 'center',
  },
  dashboardLink: {},
}))

const styleButton = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'inherit',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {},
    ...buttonStyle,
  },
})

const styleOrangeButton = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'inherit',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {},
    ...buttonStyle,
    color: '#f07662',
  },
})

const StyledIconButton = styleButton(Button)
const ITEM_HEIGHT = 48

function HeaderMenu() {
  const { mutate: logoutAction } = useAbstractMutator(AuthApi.logout)

  const { md: isDesktop } = useMedia()
  const location = useLocation()

  const store = useContext(StoreContext) as any

  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()

  const { data: currentlyLoggedInUser } = useAbstractProvider(UserApi.getCurrentlyLoggedInUser)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = async () => {
    await logoutAction({})
    store.setCurrentUser({})
    history.push('/sign-in')
  }

  const { currentUser } = store || {}

  const userRoleName = store?.currentUser?.role?.name

  return (
    <>
      {isDesktop ? (
        <>
          {currentUser?.email ? (
            <>
              <StyledIconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                disableRipple
                className={classNames(classes.profileContainer, classes.link)}
              >
                <AccountIcon />
              </StyledIconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    className={classes.menuItemTextInfo}
                    primary={`${currentlyLoggedInUser?.person?.firstName} ${currentlyLoggedInUser?.person?.lastName}`}
                    secondary={currentlyLoggedInUser?.ssoData?.sub}
                  />
                </MenuItem>
                <MenuItem onClick={logout}>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    className={classes.menuItemText}
                  >
                    {t('user_menu.logout')}
                  </ListItemText>
                  <ExitToAppIcon className={classes.menuItemIcon} />
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </>
      ) : (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 5.5,
                width: '20ch',
              },
            }}
          >
            {currentUser?.email ? (
              <div>
                <MenuItem>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    className={classes.menuItemTextInfo}
                    primary={`${currentlyLoggedInUser?.person?.firstName} ${currentlyLoggedInUser?.person?.lastName}`}
                    secondary={currentlyLoggedInUser?.ssoData?.sub}
                  />
                </MenuItem>

                {userRoleName === 'admin' ? (
                  <>
                    <Link className={classNames(classes.dashboardLink, classes.link)} to="/admin-view/avatars">
                      <MenuItem>{t('admin_menu.avatar_config')}</MenuItem>
                    </Link>
                    <Link
                      className={classNames(classes.dashboardLink, classes.link)}
                      to="/admin-view/statistics"
                    >
                      <MenuItem>{t('admin_menu.statistics')}</MenuItem>
                    </Link>
                  </>
                ) : null}
                {userRoleName === 'mentor' ? (
                  <CustomButton
                    onClick={() => {
                      store.setSelectedView(
                        store.selectedView === 'mentor' ? 'trainee' : 'mentor',
                      )
                      history.push('/')
                    }}
                  >
                    {store.selectedView === 'mentor'
                      ? t('mentor_menu.training_view')
                      : t('mentor_menu.manager_view')}
                  </CustomButton>
                ) : null}
                {userRoleName === 'trainee' || store.selectedView !== 'mentor' ? (
                  <>
                    <MenuItem>
                      <Link className={classNames(classes.dashboardLink, classes.link)} to="/">
                        {t('trainee_menu.training')}
                      </Link>
                    </MenuItem>

                    <MenuItem>
                      <Link
                        className={classNames(classes.dashboardLink, classes.link)}
                        to="/dashboard"
                      >
                        {t('trainee_menu.dashboard')}
                      </Link>
                    </MenuItem>
                  </>
                ) : null}
                {location.pathname.split('/')[1] === 'learn' ? (
                  <MenuItem
                    onClick={() => store?.setOpenSettingsModal(!store.openSettingsModal)}
                  >
                    {t('menu.settings')}
                  </MenuItem>
                ) : null}
              </div>
            ) : null}
            <Link className={classes.link} to="/contact">
              <MenuItem>{t('menu.contact')}</MenuItem>
            </Link>
            {currentUser?.email ? (
              <MenuItem onClick={logout}>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body2' }}
                  className={classes.menuItemText}
                >
                  {t('user_menu.logout')}
                </ListItemText>
                <ExitToAppIcon className={classes.menuItemIcon} />
              </MenuItem>
            ) : null}
          </Menu>
        </>
      )}
    </>
  )
}

HeaderMenu.contextType = LoggedInContext

export default observer(HeaderMenu)
