import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import { Breadcrumbs, Button, Grid, LinearProgress, makeStyles } from '@material-ui/core'
import { StoreContext } from 'App'
import AdminLayout from 'layouts/AdminLayout'
import { Link, useHistory } from 'react-router-dom'
import Text from 'components/atoms/Text/Text'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { useTranslation } from 'react-i18next'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import ChannelsApi, { ChannelImportParams, ChannelImportStatusInfo } from 'api/channels'
import useAbstractMutator from 'providers/AbstractMutator'
import { BACKEND_WS_URL } from 'helpers/configuration'

const useStyles = makeStyles({
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    marginBottom: 50,
  },
  heading: {
    marginBottom: 45,
  },
  spaceAfter: {
    marginBottom: '1em',
  },
  fileUploadGrid: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
})

const ChannelImport = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [importData, setImportData] = useState<ChannelImportParams>({ lang: 'de' } as any)
  const [importStatus, setImportStatus] = useState<ChannelImportStatusInfo>()
  const importMutator = useAbstractMutator(ChannelsApi.importChannel)

  useEffect(() => {
    const baseUrl =
      BACKEND_WS_URL ||
      `${window.location.protocol.replace(/^http/iu, 'ws')}//${window.location.host}`
    const ws = new WebSocket(`${baseUrl}/sockets/channel-import`)

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          event: 'channel-import',
          data: { token: store.currentUser.token },
        }),
      )
    }

    ws.onmessage = (event) => {
      setImportStatus(JSON.parse(event.data).data)
    }

    return () => {
      ws.close()
    }
  }, [])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/admin-view/learning-material-managment" className={classes.breadcrumb}>
              {t('admin_learning_panel.channels')}
            </Link>
            <Link
              to="/admin-view/learning-material-managment/import"
              className={classes.breadcrumb}
            >
              {t('admin_learning_panel.channel_import')}
            </Link>
          </Breadcrumbs>
          <div className={classes.container}>
            <Text variant="h2" className={classes.heading}>
              {t('admin_learning_panel.channel_import')}
            </Text>
            <Text variant="h4">{t('admin_learning_panel.import_file')}</Text>
            <Grid className={`${classes.fileUploadGrid} ${classes.spaceAfter}`} item xs={12}>
              <Button disabled={false} variant="contained" component="label" color="primary">
                {t('upload_file')}
                <input
                  disabled={false}
                  onChange={(e) => {
                    setImportData({ ...importData, file: e.target.files[0] })
                  }}
                  type="file"
                  hidden
                  accept=".zip"
                />
              </Button>
              {importData.file ? <p>{importData.file.name}</p> : undefined}
            </Grid>
            <br />
            <Grid container className={classes.spaceAfter}>
              <Grid item xs={12} md={6}>
                <MaterialSelect
                  value={importData.channelId?.toString()}
                  label={t('channel')}
                  onChange={(newChannel) => {
                    setImportData({ ...importData, channelId: newChannel })
                  }}
                  options={[
                    { name: t('admin_learning_panel.add_channel'), value: undefined },
                  ].concat(
                    store.channels.map((channel) => ({ name: channel.name, value: channel.id })),
                  )}
                />
                <p>{t('admin_learning_panel.import_channel_note')}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <MaterialSelect
                  value={importData?.lang}
                  label={t('language')}
                  onChange={(lang) => setImportData({ ...importData, lang })}
                  options={store.languages.map((lang) => ({ name: lang, value: lang }))}
                />
              </Grid>
            </Grid>
            {importStatus?.state === 'PROCESSING' || importMutator.loading ? (
              <div className={classes.spaceAfter}>
                <p>{t('admin_learning_panel.import_in_progress_note')}</p>
                <LinearProgress
                  variant={
                    importMutator.loading || importStatus?.completedSteps === 0
                      ? 'indeterminate'
                      : 'determinate'
                  }
                  value={
                    importStatus?.state === 'PROCESSING'
                      ? Math.round((importStatus.completedSteps / importStatus.totalSteps) * 100)
                      : undefined
                  }
                />
              </div>
            ) : null}
            <CustomButton
              disabled={importMutator.loading || importStatus?.state === 'PROCESSING'}
              onClick={async () => {
                importMutator.mutate(importData)
              }}
            >
              {t('import')}
            </CustomButton>
            &nbsp;
            <CustomButton
              onClick={() => history.push('/admin-view/learning-material-managment')}
              color="default"
            >
              {t('back')}
            </CustomButton>
          </div>
        </>,
      )}
    </ProtectedPage>
  )
}

export default ChannelImport
