import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumbs, makeStyles } from '@material-ui/core'
import c from 'classnames'
import { Link, useHistory, useParams } from 'react-router-dom'
import { StoreContext } from 'App'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import useAbstractProvider from 'providers/AbstractProvider'
import ProductsApi from 'api/products'
import ChannelsApi from 'api/channels'
import PhasesApi from 'api/phases'
import ConversationGroupsApi from 'api/conversationGroups'
import Text from 'components/atoms/Text/Text'
import { NavigateNext } from '@material-ui/icons'

export interface BreadcrumbsProps {
  disableLinks?: boolean
}
const useStyles = makeStyles(() => ({
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
    marginLeft: 7,
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '16px',
  },
  label: {
    fontSize: '16px',
    fontWeight: 600,
    marginRight: 5,
    lineHeight: 1.375
  },
  nextIcon: { color: '#9a0f24' },
}))

type ParamsType = {
  channelId: string
  productId: string
  phaseId: string
  conversationGroupId: string
}

const CustomBreadcrumbs: React.FC<BreadcrumbsProps> = ({ disableLinks }) => {
  const store = useContext(StoreContext)
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    channelId: channelIdParam,
    productId: productIdParam,
    phaseId: phaseIdParam,
    conversationGroupId: conversationGroupIdParam,
  } = useParams<ParamsType>()

  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const [channelName, setChannelName] = useState('')
  const [productName, setProductName] = useState('')
  const [phaseName, setPhaseName] = useState('')
  const [conversationGroupName, setConversationGroupName] = useState('')

  const channelId = Number(channelIdParam)
  const productId = Number(productIdParam)
  const phaseId = Number(phaseIdParam)

  const conversationGroupId = Number(conversationGroupIdParam)

  const productProvider = useAbstractProvider(ProductsApi.getProductByCode, null, false)
  const channelProvider = useAbstractProvider(ChannelsApi.getChannelById, null, false)
  const phaseProvider = useAbstractProvider(PhasesApi.getPhaseById, null, false)
  const conversationGroupProvider = useAbstractProvider(
    ConversationGroupsApi.getConversationById,
    null,
    false,
  )

  useEffect(() => {
    if (channelProvider.data) {
      setChannelName(channelProvider.data.name)
    }
  }, [channelProvider.data])

  useEffect(() => {
    if (productProvider.data) {
      setProductName(productProvider.data.name)
    }
  }, [productProvider.data])

  useEffect(() => {
    if (phaseProvider.data) {
      setPhaseName(phaseProvider.data.name)
    }
  }, [phaseProvider.data])

  useEffect(() => {
    if (conversationGroupProvider.data) {
      setConversationGroupName(conversationGroupProvider.data.name)
    }
  }, [conversationGroupProvider.data])

  useEffect(() => {
    const cachedProductName = store.getProductNameById(productId)
    const cachedChannelName = store.getChannelNameById(channelId)
    const cachedPhaseName = store.getPhaseNameById(phaseId)
    const cachedConversationGroupName = store.getConversationGroupNameById(conversationGroupId)

    if (cachedChannelName) {
      setChannelName(cachedChannelName)
    } else if (channelId) {
      channelProvider.refetch({ channelId })
    }
    if (cachedProductName) {
      setProductName(cachedProductName)
    } else if (productId) {
      productProvider.refetch(productId)
    }
    if (cachedPhaseName) {
      setPhaseName(cachedPhaseName)
    } else if (phaseId) {
      phaseProvider.refetch(phaseId)
    }
    if (cachedConversationGroupName) {
      setConversationGroupName(cachedConversationGroupName)
    } else if (conversationGroupId) {
      conversationGroupProvider.refetch(conversationGroupId)
    }
  }, [])

  useEffect(() => {
    const newBreadcrumbs = []

    if (channelName) {
      newBreadcrumbs.push({
        label: t('admin_learning_panel.channels'),
        title: channelName,
        path: `/admin-view/learning-material-managment/channelId=${channelId}`,
      })
    }

    if (productName) {
      newBreadcrumbs.push({
        label: t('admin_learning_panel.products'),
        title: productName,
        path: `/admin-view/learning-material-managment/channelId=${channelId}/productId=${productId}`,
      })
    }

    if (phaseName) {
      newBreadcrumbs.push({
        label: t('admin_learning_panel.phases'),
        title: phaseName,
        path: `/admin-view/learning-material-managment/channelId=${channelId}/productId=${productId}/phaseId=${phaseId}`,
      })
    }

    if (conversationGroupName) {
      newBreadcrumbs.push({
        label: t('admin_learning_panel.conversation_groups'),
        title: conversationGroupName,
        path: `/admin-view/learning-material-managment/channelId=${channelId}/productId=${productId}/phaseId=${phaseId}/conversationGroupId=${conversationGroupId}`,
      })
    }
    setBreadcrumbs(newBreadcrumbs)
  }, [channelName, productName, phaseName, conversationGroupName])

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext className={classes.nextIcon} fontSize="small" />}
    >
      {breadcrumbs?.map((item) => (
        <div className={classes.breadcrumbs}>
          <Text variant="h5" className={classes.label}>
            {item.label}:
          </Text>
          {disableLinks ? (
            <Text variant="body1" className={classes.text}>
              {item.title}
            </Text>
          ) : (
            <Link key={item.title} className={classes.breadcrumb} to={item.path}>
              <Text variant="body1" className={classes.text}>
                {item.title}
              </Text>
            </Link>
          )}
        </div>
      ))}
    </Breadcrumbs>
  )
}
export default observer(CustomBreadcrumbs)
