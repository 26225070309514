export type ProcessedTraineeDataInChannelType = {
  channelData: {
    archived: boolean
    createdAt: string | Date
    id: number
    imageUrl: string
    isActive: boolean
    name: string
    conversationGroups: {
      archived: boolean
      createdAt: string | Date
      id: number
      name: string
      productId: number
    }[]
    products: {
      archived: boolean
      createdAt: string | Date
      id: number
      name: string
      channelId: number
      code: string
    }[]
  }
  traineeData: {
    user: { firstName: string; lastName: string; email: string }
    argumentations: {
      id: number
      published: boolean
      conversationGroupId: number
      text: string
      argumentationFeedback: any[]
    }[]
    recordings: {
      id: number
      published: boolean
      conversationGroupId: number
      recordingUrl: string
      recordingFeedback: any[]
    }[]
    approvals: {
      approvalTypeId: number
      conversationGroupId: number
      createdAt: string | Date
      id: number
      userId: number
    }[]
  }
}

const processTraineeDataInChannel = ({
  channelData,
  traineeData,
}: ProcessedTraineeDataInChannelType) =>
  channelData.products.map((product) => ({
    id: product.id,
    code: product.code,
    name: product.name,
    conversationGroups: channelData.conversationGroups
      .filter((conversationGroup) => conversationGroup.productId === product.id)
      .map((conversationGroup) => ({
        id: conversationGroup.id,
        name: conversationGroup.name,
        argumentations: traineeData.argumentations.filter(
          (argumentation) => argumentation.conversationGroupId === conversationGroup.id,
        ),
        recordings: traineeData.recordings.filter(
          (recording) => recording.conversationGroupId === conversationGroup.id,
        ),
        approvals: traineeData.approvals.filter(
          (item) => item.conversationGroupId === conversationGroup.id,
        ),
      })),
  }))

const traineeHelpers = { processTraineeDataInChannel }

export default traineeHelpers
