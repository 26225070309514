import {Card, makeStyles, Paper} from '@material-ui/core'
import React from 'react'
import Text from 'components/atoms/Text/Text'

interface IProps {
  title: string
  subtitle: string
  value: any
}

const useStyles = makeStyles({
  statBoxTitle: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '20%',
    // backgroundColor: '#143a7a',
    textAlign: 'center',
    // fontWeight: 800
    paddingTop: '10px'
  },
  statBoxValue: {
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  container: {
    padding: '1rem',
  },
})

const StatBoxComponent = ({ title, subtitle, value }: IProps) => {
  const classes = useStyles()

  return (
    <>
      <Card
        elevation={2}
        className={classes.container}
      >

      <div className={classes.statBoxTitle}>
        <Text variant="h5" >
          <span style={{ color: 'black', fontWeight: 800 }}>{title}</span>
        </Text>
        <Text variant="body1" >
          <span style={{ color: 'black' }}>{subtitle}</span>
        </Text>
      </div>
      <div className={classes.statBoxValue}>
        <Text variant="body1">
          {value}
        </Text>
      </div>
      </Card>
    </>
  )
}

export default StatBoxComponent
