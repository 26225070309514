import AppBar from '@material-ui/core/AppBar'
import MenuItem from '@material-ui/core/MenuItem'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import { Button, IconButton, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Apps } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, {useContext, useEffect, useMemo} from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { StoreContext } from 'App'
import SettingsModal from 'components/organisms/SettingsModal/SettingsModal'
import { LoggedInContext } from 'context'
import useMedia from 'hooks/useMedia'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import AccountIcon from '@material-ui/icons/AccountCircleRounded'
import { useTranslation } from 'react-i18next'
import useAbstractMutator from 'providers/AbstractMutator'
import AuthApi from 'api/auth'
import NotificationsButton from 'components/notifications/NotificationsButton'
import Typography from '@material-ui/core/Typography'
import useAbstractProvider from '../../../providers/AbstractProvider'
import UserApi from '../../../api/user'
import Text from '../../atoms/Text/Text'
import {getTheRightRole, ROLES} from '../../../utils'
import HeaderMenu from '../HeaderMenu/HeaderMenu'

const buttonStyle = {
  color: '#191934',
  lineHeight: 1.75,
  fontSize: 'inherit',
  marginLeft: 15,
  marginRight: 15,
  '@media (max-width: 600px)': {
    marginLeft: 0,
    marginRight: 0,
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuItemText: {
    '& .MuiListItemText-primary': {
      color: '#00008f',
      fontWeight: 600,
    },
  },
  menuItemIcon: {
    color: '#00008f',
  },
  menuItemTextInfo: {
    marginBottom: 10,
    '& .MuiListItemText-primary': {
      color: 'grey',
      fontWeight: 600,
    },
    '& .MuiListItemText-secondary': {
      color: 'grey',
      paddingTop: 10,
      fontWeight: 300,
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
  },
  headerContainer: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.75rem',
    color: 'black',
    borderBottom: '1px solid #ccc',
  },
  addNewPost: {
    height: '46px',
    borderRadius: '30px',
    background: 'linear-gradient(45deg, #ea4d50 30%, #E31F25 90%)',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  loginButton: {
    marginRight: 20,
  },
  header: {
    width: '1320px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 16px',
    margin: 'auto',
    height: '60px',
    '@media (max-width: 992px)': {
      width: '100%',
      padding: '0px 100px',
    },
    '@media (max-width: 768px)': {
      padding: '0 16px',
    },
  },
  profileContainer: {
    padding: '0px 0px',
    minWidth: 'unset',
  },
  profileContainerMobile: {
    marginRight: 5,
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  profilePic: {
    borderRadius: 9999,
  },
  centerBoth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoText: {
    marginLeft: 15,
    fontSize: '1.5rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  settingsIcon: {
    maxHeight: '24px',
    color: '#191934',
    marginLeft: 15,
    marginRight: 15,
  },
  companyLogo: {
    marginRight: 21,
  },
  divider: {
    marginRight: 5,
    marginLeft: 5,
  },
  link: buttonStyle,
  links: {
    display: 'flex',
    alignItems: 'center',
  },
  navigationButton: {
    ...buttonStyle,
    // color: '#f07662',
    fontFamily: 'inherit',
    // fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'none',
  },
  navigationButtonSelected: {
    color: '#f07662',
    fontWeight: 700,
    cursor: 'default',
  },
  dashboardLink: {},
}))

const styleButton = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'inherit',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {},
    ...buttonStyle,
  },
})

const styleOrangeButton = withStyles({
  root: {
    textTransform: 'none',
    fontFamily: 'inherit',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus': {},
    ...buttonStyle,
    color: '#f07662',
  },
})

const StyledIconButton = styleButton(Button)
const StyledIconButtonOrange = styleOrangeButton(Button)
const StyledMenuItem = styleButton(MenuItem)

const ITEM_HEIGHT = 48

function Header() {
  const { mutate: logoutAction } = useAbstractMutator(AuthApi.logout)

  const { md: isDesktop } = useMedia()
  const location = useLocation()

  const store = useContext(StoreContext) as any

  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { t } = useTranslation()

  const { data: currentlyLoggedInUser } = useAbstractProvider(UserApi.getCurrentlyLoggedInUser)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = async () => {
    await logoutAction({})
    store.setCurrentUser({})
    history.push('/sign-in')
  }

  const { currentUser } = store || {}

  const userRoleName = store?.currentUser?.role?.name
  const roleId = getTheRightRole(store?.currentUser?.ssoData)
  const logoLink = userRoleName === 'mentor'
    ? (store.selectedView === 'manager' ? 'manager-view' : '/mentors-view')
    : '/'

  const isTraining = useMemo(() => {
    if (location.pathname === '/' && store.selectedView === 'trainee') {
      return true
    }
    return location.pathname.split('/')[1] === 'learn'
  }, [location, store.selectedView])

  const menuLinks = [
    {
      visible: (userRoleName === 'admin'),
      path: '/',
      label: t('admin_menu.admin_dashboard'),
      onClick: () => {store.setSelectedView('admin')},
      highlighted: (store.selectedView === 'admin')
    },
    {
      visible: (userRoleName === 'mentor' && ROLES.manager === roleId),
      path: '/manager-view',
      label: t('mentor_menu.statistics'),
      onClick: () => {store.setSelectedView('manager')},
      highlighted: (store.selectedView === 'manager' && location.pathname === '/manager-view')
    },
    {
      visible: (userRoleName === 'mentor'),
      path: '/mentors-view',
      label: t('mentor_menu.mentor_dashboard'),
      onClick: () => {store.setSelectedView('mentor')},
      highlighted: (store.selectedView === 'mentor' && location.pathname === '/mentors-view')
    },
    {
      visible: (!['admin', 'contentmanager'].includes(userRoleName)),
      path: '/',
      label: t('trainee_menu.training'),
      onClick: () => {store.setSelectedView('trainee')},
      highlighted: isTraining
    },
    {
      visible: (!['admin', 'contentmanager'].includes(userRoleName)),
      path: '/dashboard',
      label: t('trainee_menu.dashboard'),
      onClick: () => {store.setSelectedView('trainee')},
      highlighted: (location.pathname.split('/')[1] === 'dashboard')
    },
    {
      visible: (userRoleName !== 'admin'),
      path: '/contact',
      label: t('menu.contact'),
      highlighted: (location.pathname.split('/')[1] === 'contact')
    },
  ]

  return (
    <AppBar position="static" classes={{ root: classes.headerContainer }} elevation={0}>
      <div className={classes.header}>
        <div className={classes.leftSide}>
          <Link to={logoLink}>
            <div className={classes.centerBoth}>
              {isDesktop ? (
                store.logo ? (
                  <img alt="logo" width={399} height={43} src={store.logo} />
                ) : null
              ) : (
                <img alt="logo" height={43} src="/logo-mobile.svg" />
              )}
            </div>
          </Link>
        </div>
        <div className={classes.rightSide}>
          {!currentUser ? (
            <Link to="/sign-in">
              <Button className={classes.loginButton} color="inherit">
                {t('sign_in.button')}
              </Button>
            </Link>
          ) : null}
          {isDesktop && (
            <>
              {currentUser?.email ? (
                <>
                  <div className={classes.links}>
                    {menuLinks.filter(link => link.visible).map(link => (
                      <Link onClick={link.onClick} className={classNames(classes.dashboardLink, classes.link, ...(link.highlighted ? [classes.navigationButtonSelected] : []))} to={link.path}>
                        {link.label}
                      </Link>
                    ))}
                    {userRoleName !== 'admin' ? (
                      <>
                        {location.pathname.split('/')[1] === 'learn' ? (
                          <div
                            className={classNames(classes.pointer, classes.settingsIcon)}
                            onClick={() => store?.setOpenSettingsModal(!store.openSettingsModal)}
                          >
                            <Apps />
                          </div>
                        ) : null}

                        {currentUser?.email ? <NotificationsButton /> : null}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </>
          )}
          <HeaderMenu />
        </div>
      </div>
      {store.openSettingsModal ? <SettingsModal /> : null}
    </AppBar>
  )
}

Header.contextType = LoggedInContext

export default observer(Header)
